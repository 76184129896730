import React from "react";
import { Loading } from "./components/layout/Loading";

export const App: React.FC = () => {
  const Layout = React.lazy(() => import("./components/layout/Layout"));

  return (
    <React.Suspense fallback={<Loading />}>
      <Layout />
    </React.Suspense>
  );
};
