import React from "react";
import ReactDOM from "react-dom";
import "./style.css";
import { App } from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

// WebFont.load({
//   google: {
//     families: ["Roboto:300,400,500,700"],
//   },
// });
